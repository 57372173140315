<template>
  <div>
    <p class="detail-title" v-if="randomFlag==0">
      <strong>分类：</strong> {{ videoDetail.groupChannel }}
      <strong>作品名称：</strong> {{ videoDetail.videoName }}
      <strong>上报单位：</strong> {{ videoDetail.schoolName }}
      <strong>附件：</strong>
      <a href="#" @click="downTextFile(videoDetail)" style="color: #03a9f4;">{{ videoDetail.videoTextName }}</a>
    </p>
    <p class="detail-title" v-else>
      <strong>分类：</strong> {{ videoDetail.groupChannel }}
      <strong>视频编码：</strong> {{ videoDetail.randomCode }}
    </p>

    <section class="detail-main clearfix">
      <div style="float: right">
        <div>
          <template>
            <div class="select-zone">
              <el-select style="width: 160px" v-model="formData.channelId" clearable filterable placeholder="请输入"
                @change="queryVideoList">
                <el-option v-for="item in groupList" :key="item.channelId" :label="item.groupName" :value="item.channelId">
                </el-option>
              </el-select>
              <el-select style="width: 100px;float: right" v-model="formData.status" clearable placeholder="请选择"
                @change="queryVideoList">
                <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </template>
        </div>
        <div style="float: none" class="right pull-right">
          <el-scrollbar style="height:100vh;">
            <section class="right-item" :id="item.id" v-for="(item, index) in videoList" :key="index" @click="changeItem(item)">
              <div class="img-box">
                <el-image class="img" :src="item.mpgImg" fit="fill">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <span class="state-style is-state__success" v-if="item.status">已评</span>
                <span class="state-style is-state__error" v-else>未评</span>
                <!--<span class="timebar">21:43</span>-->
              </div>
              <p class="title" v-if="randomFlag==0">{{ item.videoName }}</p>
              <p class="title" v-else>{{ item.randomCode }}</p>
            </section>
          </el-scrollbar>
        </div>
      </div>
      <div class="left">
        <section class="video-box" v-if="videoDetail.type == '0'">
          <video controls="controls" :src="videoUrl" id="videoPlay" class="video" style="margin-top: 16px;width: 96%;max-height: 470px;margin: 16px;">
            您的浏览器不支持 video视屏播放。请使用IE8+，谷歌，火狐，360等最新浏览器查看。</video>
        </section>

        <section class="carousel-box" v-else-if="videoDetail.type == 1 && imgView">
          <!-- swiper1 -->
          <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
            <swiper-slide v-for="(item, index) in picList" :key="index">
              <p class="text-center margin-bottom__mini">{{ item.imgName }}</p>
              <div class="img-box">
                <img :src="item.imgUrl" :alt="`image${index}`" class="carousel-img" :preview="index" :preview-text="item.imgName" />
              </div>
            </swiper-slide>

            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
          </swiper>

          <!-- swiper2 Thumbs -->
          <swiper :options="swiperOptionThumbs" class="gallery-thumbs" ref="swiperThumbs">
            <swiper-slide v-for="(item, index) in picList" :key="index">
              <img :src="item.imgUrl" :alt="`image${index}`" class="carousel-img" />
            </swiper-slide>
          </swiper>
        </section>

        <!--<el-dialog :close-on-click-modal="false"
					class="z-dialog check-image-dialog"
		      :title="imgName"
		      :visible.sync="innerVisible"
		      append-to-body>
		      <div class="text-center margin-bottom__medium">
		      	<img :src="currentCheckImg" :style="{'zoom': imgZoom}"/>
		      </div>

		      <div class="text-center">
		      	<el-button @click="handleZoom('n')">放大</el-button>
		      	<el-button @click="handleZoom('s')">缩小</el-button>
		      	<el-button @click="handleZoom(1)">还原</el-button>
		      </div>
		    </el-dialog>-->

        <section class="info-box">
          <p class="info__title">评分：总分（{{ totalScore }}）</p>

          <div class="info__content">
            <el-form label-width="230px">
              <el-row style="overflow:hidden">

                <el-col :span="10" class="pingjiaxiang" v-for="(item, index) in form.itemList" :key="index"
                  :offset="index % 2 ? 3 : 0">
                  <el-form-item :label="item.itemName + '\n（满分：' + item.totalScore + '）'">
                    <i class="el-icon-question" :title="item.itemDesc" style="color: #03a9f4;"></i>
                    <el-input-number class="input-number" v-model.number="item.score" controls-position="right" :min="0"
                      :max="item.totalScore" :disabled="show"
                      :rules="[{ required: true, message: '请输入分值', trigger: 'blur' }, { type: 'number', message: '分值必须为数字值' }]"
                      @change="sumScore"></el-input-number>
                  </el-form-item>
                </el-col>

                <el-col :span="22">
                  <el-form-item label-width="0">
                    <el-input v-model="form.content" type="textarea" :autosize="{ minRows: 3 }" placeholder="综合评语"
                      :disabled="show" maxlength="500" />
                  </el-form-item>
                </el-col>

                <el-col>
                  <el-button type="primary" @click="saveVideoScore" :disabled="show">提交</el-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    swiper,
    swiperSlide
  },

  data() {
    return {
      formData: {
        channelId: '',//右侧分类id
        status: ''//右侧评价下拉属性
      },
      groupList: [],
      statusOptions: [
        {
          value: 0,
          label: '未评'
        }, {
          value: 1,
          label: '已评'
        }
      ],
      currentCheckImg: '',
      innerVisible: false,
      imgZoom: 1,
      imgName: '',
      form: {
        videoId: "",
        content: "",
        itemList: [],
      },
      picList: [],
      videoUrl: '',
      videoList: [],
      videoDetail: JSON.parse(sessionStorage.getItem("video")),
      show: false,
      swiperOptionTop: {
        spaceBetween: 10,
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        slidesPerView: 4,
        touchRatio: 0.2,
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        slideToClickedSlide: true,
      },
      imgView: false,
      totalScore: 0,
      randomFlag: sessionStorage.getItem("randomFlag")
    };
  },

  mounted() {
    var that = this;
    that.$nextTick(() => {
      that.init();
      that.queryTeacherGroupList();
      setTimeout(function () {
        that.selectVideo();
      }, 500)
    });
  },

  methods: {
    queryVideoList() {
      this.$post('/queryJudgeVideoListByParams', this.formData).then(rep => {
        this.videoList = rep.content.list;
      })
    },
    queryTeacherGroupList() {
      this.$post('/queryTeacherGroupList', {
      }).then(rep => {
        this.groupList = rep.content.groupList;
      })
    },
    selectVideo() {
      //右侧选中状态
      var videoid = this.videoDetail.id;
      $("#" + videoid + "").children(".title").css("color", "#03a9f4");
      $("#" + videoid + "").children(".img-box").css("border", "solid 2px #03a9f4");
      $("#" + videoid + "").children(".img-box").css("border-radius", "8px");
      $("#" + videoid + "").children(".img-box").css("box-shadow", "0px 0px 5px #03a9f4");
    },
    init() {
      this.imgView = false;
      if (this.videoDetail.type == 0) {
        if (this.videoDetail.stage != 3) {
          this.$message({
            type: 'info',
            message: '视频正在转码中...'
          });
          this.videoUrl = "";
        } else {
          this.videoUrl = this.videoDetail.videoUrl;
        }
      } else if (this.videoDetail.type == 1) {

        this.picList = [];

        this.loadPicListByVideoId(this.videoDetail.id).then(rep => {
          this.picList = rep.content.picList;

          if (this.picList.length > 4) {
            this.swiperOptionThumbs.slidesPerView = 4;
          } else {
            this.swiperOptionThumbs.slidesPerView = this.picList.length;
          }

          this.imgView = true;
          this.$nextTick(() => {
            this.handleSwiper();
            this.$previewRefresh();
          })
        });
      }

      this.$post('/queryJudgeVideoListByParams', this.formData).then(rep => {
        this.videoList = rep.content.list;
      })
      //获取作品评价详情包含选项分数,评语
      this.getItemDetail();

      //右侧选中状态
      this.selectVideo();
    },
    getItemDetail() {
      this.form.videoId = this.videoDetail.id;
      this.$post('/queryJudgeVideoDetail', {
        videoId: this.videoDetail.id
      }).then(rep => {

        this.form.itemList = rep.content.itemList;
        this.form.content = rep.content.content;
        this.show = rep.content.tActivity.show;

        //计算评分总分
        this.sumScore();
        //    	console.log('rep.content.tActivity.show=',rep.content.tActivity.show);
      })

    },

    sumScore() {
      this.totalScore = 0;
      for (let i = 0; i < this.form.itemList.length; i++) {
        if (this.form.itemList[i].score == null) {
          this.totalScore += parseInt(0);
        } else {
          this.totalScore += parseInt(this.form.itemList[i].score);
        }
      }
    },
    loadPicListByVideoId(videoid) {
      return this.$post('/loadPicListByVideoId', { "videoid": videoid });
    },

    changeItem(item) {
      var videoid = this.videoDetail.id;
      $("#" + videoid + "").children(".title").css("color", "#666");
      $("#" + videoid + "").children(".img-box").css("border", "");
      $("#" + videoid + "").children(".img-box").css("border-radius", "");
      $("#" + videoid + "").children(".img-box").css("box-shadow", "");
      this.videoDetail = item;
      this.init();
    },

    saveVideoScore() {
      this.show = true;
      let flag = true;
      let list = this.form.itemList;

      this.$post("/checkActivityTime", {
        activityId: this.videoDetail.activityId,
        type: 'judge'
      }).then(m => {
        var tActivity = m.content.tActivity;
        if (tActivity.show) {
          for (var i = 0; i < list.length; i++) {
            let score = list[i].score + "";
            let total = list[i].totalScore;
            //			console.log(score,score==undefined || score=='');
            if (score == undefined || score == '') {
              flag = false;
              this.$message({
                type: 'info',
                message: '有评分项没有打分，请输入分数后再提交！'
              });
              this.show = false;
              break;
            } else {
              if (score.indexOf(".") != -1) {
                flag = false;
                this.$message({
                  type: 'info',
                  message: '有评分项分数值为小数，请修改为整数后再提交！'
                });
                this.show = false;
                break;
              } else if (parseInt(score) > parseInt(total)) {
                flag = false;
                this.$message({
                  type: 'info',
                  message: '有评分项分数超过满分！'
                });
                this.show = false;
                break;
              }
            }
          }

          if (this.totalScore < 0) {
            this.$message({
              type: 'info',
              message: '总分必须大于等于0！'
            });
            flag = false;
            this.show = false;
          }else if(this.totalScore == 0){
            // console.log('content:',this.form.content);
            if(this.form.content=='' || this.form.content == null){
              this.$message({
                type: 'info',
                message: '总分等于0，必须填写综合评语！'
              });
              flag = false;
              this.show = false;
            }
          }

          if (flag) {
            //			console.log('提交后台',this.form);
            this.$post('/saveJudgeVideoDetail', {
              jsonForm: JSON.stringify(this.form)
            }).then(rep => {
              if (rep.resultCode == 0) {
                this.$message({
                  type: 'success',
                  message: '保存成功！'
                });
                this.show = false;
              }
            })

          }
        } else {
          this.$message({
            message: '评价时间已过，不能评价作品！',
            type: 'warning'
          });
          return false;
        }

      })

    },

    handleSwiper() {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    },

    checkImage(url, imgName) {
      this.imgName = imgName;
      this.currentCheckImg = url;
      this.innerVisible = true;
    },

    handleZoom(type) {
      if (type === 'n') {
        if (this.imgZoom < 3) this.imgZoom += 0.5;
        return;
      }

      if (type === 's') {
        if (this.imgZoom > 1) this.imgZoom -= 0.5;
        return;
      }

      this.imgZoom = 1;
    },

    downTextFile(obj) {
      var houzhui = obj.videoText.split(".")[1].toLowerCase();
      // console.log('在线查看文件', obj, obj.videoText, houzhui);
      if (houzhui == 'doc' || houzhui == 'docx' || houzhui == 'xls' || houzhui == 'xlsx') {
        let routeUrl = this.$router.resolve({
          path: "/ShowFile",
          query: { videoId: obj.id }
        });
        window.open(routeUrl.href, '_blank');
      } else {
        this.$message({
          type: 'info',
          message: '正在下载中……'
        });
        window.open("api/downTextFile?videoId=" + obj.id + "&access_token=" + sessionStorage.getItem("ACCESS_TOKEN"));
      }
    }

  }
};
</script>

<style lang="scss" scoped>
.select-zone {
  margin-bottom: 10px;

}

.detail-title {
  padding-bottom: 28px;
  color: #666;

  strong {
    margin-left: 30px;

    &:first-child {
      margin-left: 0;
    }
  }

}

.detail-main {
  .right {
    width: 270px;
    background-color: #fff;

    /deep/ &>.el-scrollbar>.el-scrollbar__wrap {
      overflow-x: hidden;

      &>.el-scrollbar__view {
        padding: 12px;
      }
    }

    .img-box {
      position: relative;
      margin-bottom: 20px;

      &::before {
        content: "";
        display: block;
        width: 0;
        padding-top: 61.38211%;
      }

      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }

      .state-style {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 12px;
      }

      .timebar {
        position: absolute;
        bottom: 10px;
        right: 10px;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 12px;
        border-radius: 5px;
      }
    }

    .title {
      margin-bottom: 25px;
      color: #666;
    }
  }

  .left {
    margin-right: 310px;

    .video-box,
    .carousel-box {
      height: 500px;
      margin-bottom: 13px;
      border-radius: 6px 6px 0 0;
      background-color: #fff;
    }

    .carousel-box {
      padding: 20px;

      .carousel-img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
      }
    }

    .info-box {
      border-radius: 0 0 6px 6px;
      background-color: #fff;

      .info__title {
        padding: 20px;
        color: #373d4b;
        font-size: 18px;
        border-bottom: 1px solid #e6eaee;
      }

      .info__content {
        padding: 20px;
      }
    }

    .img-box {
      position: relative;
      height: calc(100% - 20px);
    }

  }
}

.gallery-top {
  height: 80% !important;
  width: 100%;
}

/deep/.gallery-thumbs {
  height: 20% !important;
  box-sizing: border-box;
  padding: 10px 0;

  .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .swiper-slide-active {
    opacity: 1;
  }
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

.check-image-dialog {
  /deep/ .el-dialog {
    width: 95%;
    height: 95vh;
    margin-top: 2vh !important;
    margin-bottom: 2vh !important;

    .el-dialog__body {
      overflow: auto;
      height: calc(100% - 41px);
    }
  }
}

.input-number {
  min-width: 120px;
  display: inline-block;
  width: 85%;
  margin-left: 2%;
}

.pingjiaxiang {
  white-space: pre-line;
}

.el-form-item__label {
  line-height: 24px !important;
}
</style>
